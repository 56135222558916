@import url("https://fonts.googleapis.com/css?family=Archivo+Black|Dosis|Raleway|Space+Mono|Montserrat|Roboto+Slab|Abril+Fatface|Baumans|Risque|Dancing+Script|Indie+Flower|MedievalSharp|Cormorant+Garamond|Satisfy|Merienda|DM+Serif+Display|Special+Elite|Unna|Philosopher|Economica|Carter+One|Aclonica|Amita|Josefin+Slab");

body {
  padding: 0;
  margin: 0;
  max-width: 100vw;
  max-height: 100vh;
  overflow: hidden;

  font-family: "Open Sans", sans-serif;
}

p {
  margin-top: 0;
}

textarea {
  width: 100%;
  padding: 0.5rem;
  margin-bottom: 0.5rem;
  box-sizing: border-box;
  border: 1px solid black;
}

label input {
  margin-left: 0.33rem;
}

.qig-p--short-label {
  margin-bottom: 0.5rem;
  text-decoration: underline;
}

button {
  padding: 0.3rem 0.8rem;
  color: #eee;
  font-size: 0.9rem;
  border: none;
  border-radius: 0.3rem;
  background-color: #1438bd;
  box-shadow: 0 4px 8px rgba(8, 4, 1, 0.12), 0 0 8px rgba(8, 4, 1, 0.2);
  cursor: pointer;
}

.qig-button--right-space {
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
}

.qig-button--full {
  width: 100%;
  padding: 0.5rem 0.8rem;
  font-size: 1.2rem;
}

.qig-l-wrapper {
  display: grid;
  grid-template-columns: 1fr;
}

@media (min-width: 800px) {
  .qig-l-wrapper {
    grid-template-columns: 1fr 1fr;
  }

  .qig-l-wrapper--vertical {
    display: flex;
    height: 100vh;
    flex-direction: column-reverse;
    justify-content: flex-end;
  }
}

.qig-l-wrapper__form {
  padding: 1rem;
  grid-row: 2;
}

.qig-l-wrapper__result {
  grid-row: 1;
}

@media (min-width: 800px) {
  .qig-l-wrapper__result {
    padding: 1rem;
    grid-row: 2;
  }

  .qig-l-wrapper--vertical .qig-l-wrapper__result {
    grid-row: 1;
  }
}

@media (min-width: 800px) {
  .qig-l-wrapper__form {
    max-height: 90vh;
    overflow-y: scroll;
  }

  .qig-l-wrapper--vertical .qig-l-wrapper__form {
    max-height: inherit;
    flex: 1 1 auto;
  }
}

.qig-l-wrapper__randomize {
  margin-bottom: 1rem;
}

.qig__image-wrapper {
  position: relative;
  width: 100%;
  height: 700px;
  margin: 0 auto 1rem auto;
  overflow: hidden;
}

@media (min-width: 800px) {
  .qig__image-wrapper {
    height: 100vh;
    border: 1px solid black;
  }
}

.qig__image-bg {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-size: cover;
  background-position: center;
}

.qig-l-wrapper__form-item {
  padding-bottom: 1rem;
  margin-bottom: 1rem;
  border-bottom: 1px solid black;
}

.qig-l-wrapper__form-multi {
  display: flex;
  justify-content: space-between;
}

.qig-l-wrapper__form-multi > * {
  margin-right: 3rem;
}

@media (min-width: 800px) {
  .qig-l-wrapper--vertical .qig-l-wrapper__form-multi {
    justify-content: flex-start;
  }

  .qig-l-wrapper__form-multi > * {
    margin-right: 5rem;
  }
}

.qig-l-wrapper__form-header {
  margin-top: 0;
}

.qig-l-wrapper__form-subheader {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

.qig__quote {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  white-space: pre-line;
}

.qig__quote-text {
  position: relative;
  margin: 0;
  line-height: 1.2;
}

.qig__quote-text--duplicate {
  position: absolute;
  z-index: -1;
  filter: blur(12px);
}

.qig__quote-text--duplicate:first-of-type {
  opacity: 0;
}

.qig__quote-text::first-line {
  font-size: 115%;
}

.qig__quote-text::first-letter {
  font-size: 125%;
}

.qig__quote-author {
  display: none;
}

.qig__quote--font-italic {
  font-style: italic;
}

.qig__quote--font-bold {
  font-weight: 700;
}

.qig__long-buttons-wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}

.qig__buttons-wrapper {
  display: grid;
  max-width: 150px;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: repeat(5, 1fr);
}

.qig-l-slider__container {
  display: flex;
  gap: 2rem;
}

.qig-l-slider__container--column {
  flex-direction: column;
}

.qig-l-slider__label {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
}

.qig-l-slider__label-inline-text {
  display: flex;
  width: 25%;
}

.qig-l-slider__label-inline-text input[type="number"] {
  max-width: 75px;
}

.qig-l-slider__label input[type="range"] {
  width: 100%;
  margin-left: 0;

  cursor: pointer;
}

.qig-l-slider__label--disabled span {
  opacity: 0.4;
}

.qig-l-radios__wrapper {
  display: flex;
  flex-wrap: wrap;
}

.qig-l-radios__wrapper--spaced-right {
  padding-right: 1.5rem;
}

.qig-l-radios__item {
  padding-right: 1rem;
  padding-bottom: 0.5rem;
}
.qig-l-radios__item input {
  margin-right: 0.25rem;
}

.qig__tags-list {
  display: flex;
  flex-wrap: wrap;
  margin: 0 0 2rem;
  padding: 0;

  list-style-type: none;
}

.qig__tag-item {
  display: flex;
  padding: 0.5rem;
  margin-right: 1rem;
  margin-bottom: 0.5rem;
  align-items: center;

  font-size: 20px;
}

.qig__tag-button {
  display: flex;
  width: 20px;
  height: 20px;
  padding: 0 0.5rem;
  margin-right: 0.5rem;
  align-items: center;
  justify-content: center;

  font-size: 16px;

  border-radius: 100%;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.qig__tag-title--included,
.qig__tag-state--make-included {
  color: #24501e;
}

.qig__tag-state--make-included {
  background-color: #fafaf1;
}

.qig__tag-title--excluded,
.qig__tag-state--make-excluded {
  color: #710c04;
}

.qig__tag-state--make-excluded {
  background-color: #f8efec;
}

.qig__tag-title {
  padding-right: 0.5rem;

  text-transform: capitalize;
}

.qig__tag-delete {
  padding: 0.5rem;

  color: #eee;
  font-size: 16px;
  line-height: 0;
  font-weight: 700;

  background-color: #710c04;
}

.qig__screenreader {
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
}
